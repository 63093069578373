import React, { useEffect } from 'react';
import Card from 'components/card';
import { Modal, Pagination, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AffiliateProduct } from 'models/affiliateProduct';
import { affiliateService } from 'services/affiliate.service';
import Checkbox from 'components/checkbox';
import { MdClear, MdOutlineRocketLaunch, MdSync, MdInsertLink  } from 'react-icons/md';
import SearchIcon from 'components/icons/SearchIcon';
import { AffiliateStatus } from 'models/affiliateStatus';
import { toast } from 'react-toastify';
import ProductAffiliateDetail from './ProductAffiliateDetail';
import PublishForm from './PublishForm';
import moment from 'moment';

const AffiliateTable = () => {
    const [products, setProducts] = React.useState<AffiliateProduct[]>([]);
    const [total, setTotal] = React.useState<number>(0);
    const [params, setParams] = React.useState<any>({ page: 1, pageSize: 20 });
    const [checked, setChecked] = React.useState<any>({});
    const [selectedProductIds, setSelectedProductIds] = React.useState<number[]>([]);
    const statusOptions = [
        '',
        AffiliateStatus.new,
        AffiliateStatus.generated,
        AffiliateStatus.waitToPublish,
        AffiliateStatus.published,
        AffiliateStatus.invalid,
        AffiliateStatus.error
    ];
    const [detailProduct, setDetailProduct] = React.useState<AffiliateProduct>();
    const [openDetail, setOpenDetail] = React.useState<boolean>(false);
    const [openForm, setOpenForm] = React.useState<boolean>(false);

    useEffect(() => {
        affiliateService.getAll(params).then((res) => {
            setProducts(res.data);
            setTotal(res.total);
        });
    }, [params]);

    const changeParams = (name: string, value: any) => {
        const param = {
            ...params,
        };
        param[name] = value;
        setParams({ ...param });
    }

    const handleSelectStatus = (value: any) => {
        params.status = value;
        setParams({ ...params });
    };

    const handlePageChange = (page: number) => {
        params.page = page;
        setParams({ ...params });
    };

    const onChangeCheckbox = (e: any) => {
        const isCheck = e.target.checked;
        const id = e.target.id;

        const listCheck = { ...checked };
        if (id == "0") {
            (products || []).forEach(product => {
                listCheck[product.platformProductId.toString()] = isCheck;
            });
            listCheck["0"] = isCheck;
        } else {
            listCheck[id] = isCheck;
        }

        setChecked(listCheck);
        const ids: number[] = [];
        Object.keys(listCheck).forEach(key => {
            if (listCheck[key] && key != "0") {
                ids.push(parseInt(key));
            }
        })
        setSelectedProductIds(ids);
    };

    const updateStatus = (status: string) => {
        if (selectedProductIds.length == 0) {
            toast.error("Hãy chọn 1 sản phẩm");
            return;
        }

        affiliateService.updateStatus(selectedProductIds, status)
            .then(() => {
                toast.success(`Đã cập nhật ${selectedProductIds.length} sản phẩm thành công`);
                setParams({ ...params });
            })
            .catch(e => {
                toast.error(`Cập nhật ${selectedProductIds.length} sản phẩm thất bại`);
            })
    }

    const refresh = () => {
        setParams({ ...params });
    };

    const openModalDetail = async (product: AffiliateProduct) => {
        setDetailProduct(product);
        setOpenDetail(true);
    }

    const publish = () => {
        if (selectedProductIds.length == 0) {
            toast.error("Hãy chọn 1 sản phẩm");
            return;
        }

        setOpenForm(true);
    }

    const columns: ColumnsType<AffiliateProduct> = [
        {
            title: <Checkbox id={"0"} checked={checked["0"]} onChange={onChangeCheckbox}></Checkbox>,
            width: "40px",
            align: 'center',
            render: (value, record) => <Checkbox onChange={onChangeCheckbox}
                id={record?.platformProductId.toString()}
                checked={checked[record?.platformProductId.toString()]}
            >
            </Checkbox>,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: "300px",
            render: (value) => (
                <p className="text-[#333] dark:text-white">{value}</p>
            ),
        },
        {
            title: 'Product ID',
            dataIndex: 'platformProductId',
            key: 'platformProductId',
            width: "50px",
            render: (value) => (
                <p className="text-[#333] dark:text-white">{value}</p>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: "150px",
            render: (value) => (
                <p className="text-[#333] dark:text-white">{value}</p>
            ),
        },
        {
            title: 'Domain',
            dataIndex: 'domain',
            key: 'domain',
            render: (value, record) => (
                <p className="text-[#333] dark:text-white">{value} {record.status == AffiliateStatus.published && (<a target='_blank' href={record.publishedProductUrl}><MdInsertLink /></a>)}</p>
            ),
        },
        {
            title: 'Publish Date',
            dataIndex: 'publishedDate',
            key: 'publishedDate',
            render: (value) => (
                <p className="text-[#333] dark:text-white">{value && moment(value).format('DD/MM/YYYY hh:mm a')}</p>
            ),
        },
        {
            title: 'DETAIL',
            dataIndex: 'actions',
            align: 'center',
            key: 'actions',
            width: "100px",
            render: (action, record) => (
                <div className="flex items-center justify-center">
                    <a
                        className="btn btn-default inline-block !w-[100px]"
                        onClick={(e) => {
                            e.preventDefault();
                            openModalDetail(record);
                        }}
                        title="Click to view detail"
                    >
                        Detail
                    </a>
                </div>
            ),
        },
    ];

    return (
        <Card extra={'w-full h-full sm:overflow-auto px-6'}>
            <div
                className="search-box mt-[20px] flex w-full items-center gap-[20px] rounded-xl pt-[20px]"
                id="search-box"
            >
                <div className="input-group flex-1">
                    <label className="dark:!bg-[#111c44] dark:text-[#fff]">
                        Product ID
                    </label>
                    <div className={`input-form dark:!bg-[#111c44] dark:text-white w-40`}>
                        <SearchIcon />
                        <input
                            value={params.platformProductId || ""}
                            onChange={(e) => changeParams('platformProductId', e.target.value)}
                            type="text"
                            placeholder=""
                            className={`input-text dark:!bg-[#111c44] dark:text-white`}
                        />
                    </div>
                </div>
                <div className="input-group flex-1" id="search-status">
                    <label className="dark:!bg-[#111c44] dark:text-[#fff]">Status</label>
                    <div className={`input-form dark:!bg-[#111c44] dark:text-white`}>
                        <SearchIcon />
                        <Select
                            defaultValue={statusOptions[0]}
                            onChange={(value) => handleSelectStatus(value)}
                            options={statusOptions.map((status) => ({
                                label: status,
                                value: status,
                            }))}
                            className="!h-[37px] border-none"
                        />
                    </div>
                </div>
                <div className="input-group flex-1">
                    <label className="dark:!bg-[#111c44] dark:text-[#fff]">
                        Domain
                    </label>
                    <div className={`input-form dark:!bg-[#111c44] dark:text-white w-40`}>
                        <SearchIcon />
                        <input
                            value={params.domain || ""}
                            onChange={(e) => changeParams('domain', e.target.value)}
                            type="text"
                            placeholder=""
                            className={`input-text dark:!bg-[#111c44] dark:text-white`}
                        />
                    </div>
                </div>
            </div>
            <div className="flex w-full gap-[20px] rounded-xl pt-[20px]">
                <a className="dark:bg-[#fff] dark:text-[#11047a] flex items-center cursor-pointer"
                    onClick={() => publish()}
                >
                    <MdOutlineRocketLaunch /> <span className='pl-1'>Publish ({selectedProductIds.length})</span>
                </a>
                <a className="dark:bg-[#fff] dark:text-[#11047a] flex items-center cursor-pointer"
                    onClick={() => updateStatus(AffiliateStatus.new)}>
                    <MdSync /> <span className='pl-1'>Retry ({selectedProductIds.length})</span>
                </a>
                <a className="dark:bg-[#fff] dark:text-[#11047a] flex items-center cursor-pointer"
                    onClick={() => updateStatus(AffiliateStatus.invalid)}>
                    <MdClear /> <span className='pl-1'>Set Invalid ({selectedProductIds.length})</span>
                </a>
                <a className="dark:bg-[#fff] dark:text-[#11047a] flex items-center cursor-pointer"
                    onClick={() => refresh()}>
                    <MdSync /> <span className='pl-1'>Refresh</span>
                </a>
            </div>
            <div className="content-box mt-8 overflow-x-auto xl:overflow-x-hidden">
                <Table
                    dataSource={products}
                    columns={columns}
                    pagination={false}
                    rowClassName="dark:!bg-navy-800 dark:text-white"
                    rowKey="id"
                    sortDirections={['ascend', 'descend']}
                    showSorterTooltip={false}
                />
                <Pagination
                    current={params["page"]}
                    pageSize={params["pageSize"]}
                    total={total}
                    onChange={handlePageChange}
                    className="pagination-box my-[20px] flex justify-end"
                />
            </div>

            <Modal
                title="Product Detail"
                bodyStyle={{overflowX: 'scroll'}}
                open={openDetail}
                centered
                onCancel={() => setOpenDetail(false)}
                footer={null}
                width={700}
            >
                <>
                    <ProductAffiliateDetail product={detailProduct}></ProductAffiliateDetail>
                </>
            </Modal>

            <Modal
                title={`Publish ${selectedProductIds.length} products`}
                open={openForm}
                centered
                onCancel={() => setOpenForm(false)}
                footer={null}
                width={500}
            >
                <>
                    <PublishForm onClose={() => {setOpenForm(false); setParams({...params})}} productIds={selectedProductIds}/>
                </>
            </Modal>
        </Card>
    );
};

export default AffiliateTable;
