import Card from "components/card";
import SearchTableProducts from '../components/search/SearchTableProducts';

const ProductSearchPage = () => {
  return (
    <Card extra={"w-full h-full bg-white mt-3"}>
      <SearchTableProducts />
    </Card>
  );
};

export default ProductSearchPage;
