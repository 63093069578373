import { API_URL } from 'app-constants';
import axiosClient from './axiosClient';

class SettingService {
    save = (settings: { key: string, value: string }[]) => {
        return axiosClient
            .post(API_URL + "/settings/save", settings);
    }

    getAll = () => {
        return axiosClient
        .get(API_URL + '/settings')
        .then((response) => {
          return response.data as {key: string, value: string}[];
        });

        return Promise.resolve([
            {
                key: 'openaiOrganization',
                value: 'org-2mjqLsN6IMmSQ65CJAV8jD6t'
            },
            {
                key: 'openaiApiKey',
                value: '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'
            },
            {
                key: 'openaiDescription',
                value: 'Viết bài 500 từ (format html + chuẩn SEO) giới thiệu sản phẩm {title} (Bao gồm Thông tin sản phẩm, Vì sao sử dụng, Công dụng, cách dùng). Chỉ trả về nội dung trong <body></body> và không chứa thẻ html, head, body'
            },
            {
                key: 'openaiShortDescription',
                value: 'Viết mô tả dưới 30 từ về sản phẩm {title}'
            },
            {
                key: 'shopeeAffiliateToken',
                value: 'salebeat_10f0bb1d-56f1-4318-8652-53377746ed61'
            },
            {
                key: 'lazadaAffiliateToken',
                value: 'salebeat_10f0bb1d-56f1-4318-8652-53377746ed61'
            }
        ])
    }
}

export const settingService = new SettingService();