import { createColumnHelper } from '@tanstack/table-core';
import { Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Status } from 'commons/constants';
import { notify } from 'commons/customNotify';
import { DateOptions, formatDate } from 'commons/formatDate';
import TableCustomer from 'components/table/table';
import { playerDetail } from 'features/player/pages/PlayerDetailPage';
import { PriceHistory, SaleHistory } from 'models';
import { useEffect, useState } from 'react';
import { updateUserStatus } from 'services/player.service';

interface IProps {
  prices: PriceHistory[];
}

const ProductPriceHistory = ({ prices }: IProps) => {
  const dataSource = prices.reverse().map((lp, index) => {
    return {
      ...lp,
      key: index + 1,
    };
  });

  // platformId: number;
  // platformProductId: number;
  // price: number;
  // priceBeforeDiscount: number;
  // priceMin: number;
  // priceMax: number;
  // discount: number;
  // shopId: number;
  // createdDate: Date;

  const columns: ColumnsType<any> = [
    {
      title: (
        <p className="text-center text-sm font-bold uppercase text-[#333] dark:text-gray-600">
          No
        </p>
      ),
      dataIndex: 'key',
      key: 'key',
      render: (info) => (
        <div className="text-[#333] dark:text-white">{info}</div>
      ),
    },
    {
      title: (
        <p className="text-center text-sm font-bold uppercase text-[#333] dark:text-gray-600">
          Price
        </p>
      ),
      dataIndex: 'price',
      key: 'price',
      render: (value) => (
        <div className="text-[#333] dark:text-white">{value}</div>
      ),
    },
    {
      title: (
        <p className="text-center text-sm font-bold uppercase text-[#333] dark:text-gray-600">
          PriceBeforeDiscount
        </p>
      ),
      dataIndex: 'priceBeforeDiscount',
      key: 'priceBeforeDiscount',
      render: (value) => (
        <div className="text-[#333] dark:text-white">{value}</div>
      ),
    },
    {
      title: (
        <p className="text-center text-sm font-bold uppercase text-[#333] dark:text-gray-600">
          Discount
        </p>
      ),
      dataIndex: 'discount',
      key: 'discount',
      render: (value) => (
        <div className="text-[#333] dark:text-white">{value}</div>
      ),
    },
    {
      title: (
        <p className="text-center text-sm font-bold uppercase text-[#333] dark:text-gray-600">
          PriceMin
        </p>
      ),
      dataIndex: 'priceMin',
      key: 'priceMin',
      render: (value) => (
        <div className="text-[#333] dark:text-white">{value}</div>
      ),
    },
    {
      title: (
        <p className="text-center text-sm font-bold uppercase text-[#333] dark:text-gray-600">
          PriceMax
        </p>
      ),
      dataIndex: 'priceMax',
      key: 'priceMax',
      render: (value) => (
        <div className="text-[#333] dark:text-white">{value}</div>
      ),
    },
  ];

  return (
    <div className="player-detail-tab-lock" id="player-detail-tab-lock">
      <Table bordered columns={columns} dataSource={dataSource} />
    </div>
  );
};
export default ProductPriceHistory;
