import { API_URL } from 'app-constants';
import axiosClient from './axiosClient';
import { Website } from 'models/website';

class WebsiteService {
  add = (website: Website) => {
    return axiosClient
      .post(API_URL + "/websites/add", website);
  }

  getAll = () => {
    return axiosClient
    .get(API_URL + '/websites')
    .then((response) => {
      return response.data as Website[];
    });
  }

  remove = (domain: string) => {
    return axiosClient
      .delete(API_URL + "/websites/remove", {data: {domain}});
  }
}

export const websiteService = new WebsiteService();