import IconLoading from "./loading-icon"

const Loading = () => {
    return (
        <div className="loading-box flex items-center py-[50px] w-full justify-items-center relative">
            <div className="loader absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] w-[50px]">
                <IconLoading />
            </div>
        </div>
    )
}
export default Loading