import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SaleHistory } from 'models';

interface IProps {
  sales: SaleHistory[];
}

const ProductSaleHistory = ({ sales }: IProps) => {
  const dataSource = sales.reverse().map((lp, index) => {
    return {
      ...lp,
      key: index + 1,
    };
  });

  const columns: ColumnsType<any> = [
    {
      title: (
        <p className="text-center text-sm font-bold uppercase text-[#333] dark:text-gray-600">
          No
        </p>
      ),
      dataIndex: 'key',
      key: 'key',
      render: (info) => (
        <div className="text-[#333] dark:text-white">{info}</div>
      ),
    },
    {
      title: (
        <p className="text-center text-sm font-bold uppercase text-[#333] dark:text-gray-600">
          Value
        </p>
      ),
      dataIndex: 'value',
      key: 'value',
      render: (value) => (
        <div className="text-[#333] dark:text-white">{value}</div>
      ),
    },
    {
      title: (
        <p className="text-center text-sm uppercase text-[#333] dark:text-gray-600">Total</p>
      ),
      dataIndex: 'total',
      key: 'total',
      render: (value) => (
        <div className="text-[#333] dark:text-white">{value}</div>
      ),
    },
    {
      title: (
        <p className="text-center text-sm uppercase text-[#333] dark:text-gray-600">Date</p>
      ),
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (value) => (
        <div className="text-[#333] dark:text-white">{value}</div>
      ),
    },
  ];

  return (
    <div className="player-detail-tab-lock" id="player-detail-tab-lock">
      <Table bordered columns={columns} dataSource={dataSource} />
    </div>
  );
};
export default ProductSaleHistory;
