import React from "react";
import Footer from "components/footer/FooterAuthCentered";
import Sidebar from "components/sidebar";

const Centered = (props: { maincard: JSX.Element }) => {
  const [open, setOpen] = React.useState(true);
  const { maincard } = props;
  const token = localStorage.getItem('token');
  return (
    <div className="flex min-h-screen w-full flex-col self-center justify-self-center overflow-hidden lg:mx-0">
      {token?<Sidebar variant="auth" open={open} onClose={() => setOpen(false)} />: <></>}
      
      {/* <NavbarAuth onOpenSidenav={() => setOpen(!open)} />
        */}
      {/* <FixedPlugin /> */}
      <div className="bg-images absolute top-0 left-0 right-0 bottom-0"></div>
      {/* <div className="absolute left-0 right-0 top-0 max-h-[48vh] min-h-[48vh] w-full overflow-hidden bg-gradient-to-br from-brandLinear to-blueSecondary bg-cover bg-no-repeat md:mx-auto" /> */}
      {/* bgImage={image} */}
      {maincard}
      <Footer />
    </div>
  );
};

export default Centered;
