import { Card } from "antd";
import InputField from 'components/fields/InputField';
import { Website } from "models/website";
import { useState } from "react";
import { toast } from 'react-toastify';
import { websiteService } from "services/website.service";

const WebsiteForm = (props: {
    onClose?: any;
  }) => {
    const [website, setWebsite] = useState<Website>({} as Website);

    const onChange = (key: string, value: any) => {
        setWebsite({
            ...website,
            [key]: value
        })
    }

    const create = () => {
        console.log(website);
        if(!website.url || !website.domain || !website.consumerSecret || !website.consumerKey) {
            toast.error("Hãy điền đủ thông tin");
            return;
        }

        websiteService.add(website).then(() => {
            toast.success("Thành công");
            props.onClose();
        })
        .catch(() => {
            toast.error("Tạo website thất bại");
        })
    }

    return (
        <Card>
            <InputField
                id="domain"
                extra="mb-3"
                label="Domain"
                placeholder="example.com"
                type="text"
                value={website.domain}
                onChange={(e: any) => onChange('domain', e.target.value)}
            />
            <InputField
                id="url"
                extra="mb-3"
                label="URL"
                placeholder="https://example.com"
                type="text"
                value={website.url}
                onChange={(e: any) => onChange('url', e.target.value)}
            />
            <InputField
                id="consumerKey"
                extra="mb-3"
                label="Consumer Key"
                placeholder=""
                type="text"
                value={website.consumerKey}
                onChange={(e: any) => onChange('consumerKey', e.target.value)}
            />
            <InputField
                id="consumerSecret"
                extra="mb-3"
                label="Consumer Secret"
                placeholder=""
                type="text"
                value={website.consumerSecret}
                onChange={(e: any) => onChange('consumerSecret', e.target.value)}
            />

            <div className="mt-[30px] flex justify-center">
                <button
                    className="linear flex flex-row items-center justify-center rounded-xl bg-[#11047a] px-4 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 lg:min-w-[250px]"
                    onClick={create}
                >
                    Create
                </button>
            </div>
        </Card>
    );
};

export default WebsiteForm;
