import { API_URL } from 'app-constants';
import axiosClient from './axiosClient';

class AffiliateService {
  add = (ids: number[]) => {
    return axiosClient
      .post(API_URL + "/affiliateProducts/add", { ids })
      .then((response) => {
        return response.data;
      });
  }

  getAll = (params: any) => {
    return axiosClient
    .get(API_URL + '/affiliateProducts', { params })
    .then((response) => {
      return response.data;
    });
  }

  updateStatus = (ids: number[], status: string) => {
    return axiosClient
      .post(API_URL + "/affiliateProducts/updatestatus", { ids, status })
      .then((response) => {
        return response.data;
      });
  }

  publish = (ids: number[], domain: string) => {
    return axiosClient
      .post(API_URL + "/affiliateProducts/publish", { ids, domain })
      .then((response) => {
        return response.data;
      });
  }
}

export const affiliateService = new AffiliateService();