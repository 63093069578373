import { API_URL } from "app-constants";
import axiosClient from "./axiosClient";

export const updateProductContent = (body: any) => {
    return axiosClient
      .post(API_URL + "/product_detail/update_content", body)
      .then((response) => {
        return response.data;
      });
}
