import Card from "components/card";
import WebsiteTable from "../components/WebsiteTable";

const WebsitePage = () => {
  return (
    <Card extra={"w-full h-full bg-white mt-3"}>
      <WebsiteTable />
    </Card>
  );
};

export default WebsitePage;
