export const optionSearchList = [
    {
      label: 'Tournament Code',
      code: 'tournamentCode',
      placeholder: '12345'
    },
    {
      label: 'AAMS Code',
      code: 'aamsCode',
      placeholder: '12345'
    },
    {
      label: 'AAMS Ticket',
      code: 'aamsTicket',
      placeholder: '12345'
    },
  ];

export enum BrandTexts {
  DASHBOARD = "dashboard",
  PLAYERS = "players",
  TOURNAMENT = "tournament",
  GAME_SESSIONS ="game-sessions",
  REPORTING="reporting",
  SETIINGS="settings"
}

export enum Status {
  LOCK = "Lock",
  ACTIVE = "Active"
}

export const TEXT_AREA_MAX_LENGTH = 2000;