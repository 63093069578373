import React, { useEffect, useState } from 'react';
import Card from 'components/card';
import { Modal, Pagination, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Website } from '../../../models/website';
import { MdOutlineAddShoppingCart, MdDeleteForever } from "react-icons/md";
import { websiteService } from 'services/website.service';
import WebsiteForm from './WebsiteForm';
import { toast } from 'react-toastify';

const WebsiteTable = () => {
    const [params, setParams] = React.useState<any>({ page: 1, pageSize: 20 });
    const [openForm, setOpenForm] = React.useState<boolean>(false);

    const [listWebsites, setListWebsites] = useState<Website[]>([]);

    useEffect(() => {
        websiteService.getAll().then((res) => {
            setListWebsites(res);
        });
    }, [params]);

    const addNew = () => {
        setOpenForm(true);
    };

    const remove = (domain: string) => {
        websiteService.remove(domain).then((res) => {
            toast.success(`Xóa ${domain} thành công!`);
            setParams({...params});
        });
    }

    const columns: ColumnsType<Website> = [
        {
            title: '',
            dataIndex: 'actions',
            align: 'center',
            key: 'actions',
            width: "20px",
            render: (action, record) => (
                <div className="flex items-center justify-center">
                    <a
                        className="inline-block !w-[20px] text-lg text-red-700"
                        onClick={(e) => {
                            e.preventDefault();
                            remove(record.domain);
                        }}
                        title="Click to remove"
                    >
                        <MdDeleteForever />
                    </a>
                </div>
            ),
        },
        {
            title: 'Domain',
            dataIndex: 'domain',
            key: 'domain',
            align: 'left',
            render: (domain) => <span className="text-[#333] dark:text-white">{domain}</span>,
        },
        {
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
            align: 'left',
            render: (url) => <span className="text-[#333] dark:text-white">{url}</span>,
        },
        {
            title: 'Consumer key',
            dataIndex: 'consumerKey',
            key: 'consumerKey',
            align: 'left',
            render: (consumerKey) => <span className="text-[#333] dark:text-white">{consumerKey}</span>,
        },
        {
            title: 'Consumer secret',
            dataIndex: 'consumerSecret',
            key: 'consumerSecret',
            align: 'left',
            render: (consumerSecret) => <span className="text-[#333] dark:text-white">{consumerSecret}</span>,
        }
    ];

    return (
        <Card extra={'w-full h-full sm:overflow-auto px-6'}>
            <div className="flex w-full gap-[20px] rounded-xl pt-[20px]">
                <a className="dark:bg-[#fff] dark:text-[#11047a] flex items-center cursor-pointer"
                    onClick={() => addNew()}
                >
                    <MdOutlineAddShoppingCart /> <span className='pl-1'>New</span>
                </a>
            </div>
            <div className="content-box mt-8 overflow-x-auto xl:overflow-x-hidden">
                <Table
                    dataSource={listWebsites}
                    columns={columns}
                    pagination={false}
                    rowClassName="dark:!bg-navy-800 dark:text-white"
                    rowKey="id"
                    sortDirections={['ascend', 'descend']}
                    showSorterTooltip={false}
                />
            </div>

            <Modal
                title="New website"
                open={openForm}
                centered
                onCancel={() => setOpenForm(false)}
                footer={null}
                width={500}
            >
                <>
                    <WebsiteForm onClose={() => {setOpenForm(false); setParams({...params})}}/>
                </>
            </Modal>
        </Card>
    );
};

export default WebsiteTable;
