import { Select } from 'antd';

function SelectField(props: {
  id: string;
  label: string;
  extra?: string;
  placeholder: string;
  variant?: string;
  state?: string;
  disabled?: boolean;
  onChange?: any;
  onKeyDown?: any;
  error?: string;
  options?: any;
  defaultValue?: any;
  value?: string;
  isRequired?: boolean;
}) {
  const {
    label,
    id,
    extra,
    variant,
    state,
    disabled,
    onChange,
    onKeyDown,
    error,
    options,
    defaultValue,
    placeholder,
    value,
    isRequired,
  } = props;

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-white ${
          variant === 'auth' ? 'ml-1.5 font-medium' : 'ml-3 font-bold'
        }`}
      >
        {label}
        {isRequired && <span className="text-red-500">*</span>}
      </label>
      <Select
        defaultValue={defaultValue}
        onChange={onChange}
        options={options}
        id={id}
        disabled={disabled}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        showSearch
        value={value}
        optionFilterProp="children"
        filterOption={(input, option) => {
          const label = option?.label;
          if (typeof label === 'string' && typeof input === 'string') {
            return label.toLowerCase().includes(input.toLowerCase());
          }
          return false;
        }}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '')
            .toString()
            .toLowerCase()
            .localeCompare((optionB?.label ?? '').toString().toLowerCase())
        }
        className="select-field mt-2 flex h-12 w-full items-center justify-center rounded-xl bg-white/0 text-sm outline-none"
      />
      {error && <p className="mt-[10px] text-[14px] text-red-500">{error}</p>}
    </div>
  );
}

export default SelectField;
