import Card from "components/card";
import AffiliateTable from "../components/AffiliateTable";

const AffiliatePage = () => {
  return (
    <Card extra={"w-full h-full bg-white mt-3"}>
      <AffiliateTable />
    </Card>
  );
};

export default AffiliatePage;
