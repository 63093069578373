import { API_URL } from 'app-constants';
import axiosClient from './axiosClient';

export const getListProducts = (params: string) => {
  return axiosClient
    .get(API_URL + '/products', { params })
    .then((response) => {
      return response.data;
    });
};

export const updateProductStatus = (platformProductIds: number[], status: string) => {
  return axiosClient
    .post(API_URL + "/products/update_status", { platformProductIds, status })
    .then((response) => {
      return response.data;
    });
}

export const getProductDetail = (platformProductId: number) => {
  return axiosClient
    .get(API_URL + `/product_detail?platformProductIds=[${platformProductId}]`, { })
    .then((response) => {
      return response.data;
    }); 
}

export const getPriceHistory = (platformProductId: number) => {
  return axiosClient
    .get(API_URL + `/price_histories?platformProductIds=[${platformProductId}]`, { })
    .then((response) => {
      return response.data;
    }); 
}

export const getSaleHistory = (platformProductId: number) => {
  return axiosClient
    .get(API_URL + `/sale_histories?platformProductIds=[${platformProductId}]`, { })
    .then((response) => {
      return response.data;
    }); 
}
