import { Routes, Route } from "react-router-dom";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import "assets/css/Plugins.css";

const App = () => {
  return (
    <Routes>
      <Route path="/*" element={<AdminLayout />} />
      <Route path="auth/*" element={<AuthLayout />} />
    </Routes>
  );
};

export default App;
