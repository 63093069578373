import { useMemo } from "react";

const parseQueryStringToObject = (queryString: string) => {
  const string = queryString.substring(1);
  const arr = string.split('&');
  const obj: { [key: string]: any } = {};
  arr.forEach((item) => {
    const [key, value] = item.split('=');
    if (key && value) {
      if(obj[key])
      {
        obj[key] = Array.isArray(obj[key]) ? [...obj[key], value] : [obj[key], value];
      }
      else
      {
        obj[key] = value;
      }
    }
  });
  return obj;
};

export const convertParamToQueryString = (data: any) => {
  const params = new URLSearchParams()

  Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
          value.forEach(value => params.append(key, value?.toString() || ''))
      } else {
          params.append(key, value?.toString() || '')
      }
  });

  return params.toString();
}

export const useQuery = (search: string) => {
  return useMemo(() => parseQueryStringToObject(search), [search]);
};

