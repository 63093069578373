import Card from 'components/card';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import ProductSaleHistory from '../components/tabs-detail/ProductSaleHistory';
import ProductDetailTab from '../components/tabs-detail/ProductDetailTab';
import ProductPriceHistory from '../components/tabs-detail/ProductPriceHistory';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import Loading from 'features/components/loading';
import { Link } from 'react-router-dom';
import { PriceHistory, Product, ProductDetail, SaleHistory } from 'models';
import { getPriceHistory, getProductDetail, getSaleHistory } from 'services/product.service';

type Props = {
  product: Product;
};

const ProductDetailPage = ({ product }: Props) => {
  const url = useLocation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [prices, setPrices] = React.useState<PriceHistory[]>([]);
  const [sales, setSales] = React.useState<SaleHistory[]>([]);
  const [detail, setDetail] = React.useState<ProductDetail>();
  
  const fetchData = async (id: number) => {
    try {
      const details = await getProductDetail(id);
      const saleHistories = await getSaleHistory(id);
      const priceHistories = await getPriceHistory(id);

      if (details && details.length > 0) setDetail(details[0]);
      setSales(saleHistories);
      setPrices(priceHistories);

      setLoading(false);
    } catch (error) {
      setLoading(true);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(product?.platformProductId);
  }, [product]);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Detail`,
      children: <> 
        {loading ? (
          <Loading />
        ) : (
        <ProductDetailTab detail={detail} product={product} />
        )}
      </>,
    },
    {
      key: '2',
      label: `Price history`,
      children: <> 
        {loading ? (
          <Loading />
        ) : (
        <ProductPriceHistory prices={prices} />
        )}
      </>,
    },
    {
      key: '3',
      label: `Sale history`,
      children: <> 
        {loading ? (
          <Loading />
        ) : (
        <ProductSaleHistory sales={sales}/>
        )}
      </>,
    },
  ];

  return (
    <Card extra={'w-full h-full bg-white mt-3 px-4 pt-3 pb-8'}>
      <p className="shrink text-[33px] capitalize text-[#11047a] dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-[#11047a] dark:hover:text-white"
          >
            {/* {playerDetail?.fullName || ''} */}
          </Link>
        </p>
      <Tabs
        defaultActiveKey="1"
        items={items}
        size="large"
        className="dark:text-[#fff]"
      />
    </Card>
  );
};
export default ProductDetailPage;
