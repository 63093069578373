import { Card } from "antd";
import InputField from 'components/fields/InputField';
import TextField from "components/fields/TextField";
import { Website } from "models/website";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { settingService } from "services/setting.service";

const SettingForm = () => {
    const [setting, setSetting] = useState<any>({} as any);

    useEffect(() => {
        settingService.getAll().then((res) => {
            const obj = {} as any;
            res.forEach((item) => {
                obj[item.key] = item.value
            })

            setSetting(obj);
        });
    }, []);

    const onChange = (key: string, value: any) => {
        setSetting({
            ...setting,
            [key]: value
        })
    }

    const save = () => {
        // convert to key-value
        const keyValuePairs = Object.keys(setting).map((key) => {
            return {
                key,
                value: setting[key]
            }
        })

        settingService.save(keyValuePairs).then(() => {
            toast.success("Thành công");
        })
        .catch(() => {
            toast.error("Có lỗi xảy ra");
        })
    }

    return (
        <Card>
            <InputField
                id="openaiOrganization"
                extra="mb-3"
                label="Open AI Organization"
                placeholder=""
                type="text"
                value={setting.openaiOrganization}
                onChange={(e: any) => onChange('openaiOrganization', e.target.value)}
            />
            <InputField
                id="openaiApiKey"
                extra="mb-3"
                label="Open AI API Key"
                placeholder=""
                type="text"
                value={setting.openaiApiKey}
                onChange={(e: any) => onChange('openaiApiKey', e.target.value)}
            />
            <TextField
                id="openaiDescription"
                extra="mb-3"
                label="Open AI Product Description"
                placeholder=""
                rows={3}
                cols={100}
                value={setting.openaiDescription}
                onChange={(e: any) => onChange('openaiDescription', e.target.value)}
            />
            <TextField
                id="openaiShortDescription"
                extra="mb-3"
                label="Open AI Product Short Description"
                placeholder=""
                rows={2}
                cols={100}
                value={setting.openaiShortDescription}
                onChange={(e: any) => onChange('openaiShortDescription', e.target.value)}
            />
            <InputField
                id="shopeeAffiliateId"
                extra="mb-3"
                label="Shopee Affiliate Id"
                placeholder=""
                type="text"
                value={setting.shopeeAffiliateId}
                onChange={(e: any) => onChange('shopeeAffiliateId', e.target.value)}
            />
            {/* <InputField
                id="lazadaAffiliateToken"
                extra="mb-3"
                label="Lazada Affiliate Token"
                placeholder=""
                type="text"
                value={setting.lazadaAffiliateToken}
                onChange={(e: any) => onChange('lazadaAffiliateToken', e.target.value)}
            /> */}

            <div className="mt-[30px] flex justify-center">
                <button
                    className="linear flex flex-row items-center justify-center rounded-xl bg-[#11047a] px-4 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 lg:min-w-[250px]"
                    onClick={save}
                >
                    Save
                </button>
            </div>
        </Card>
    );
};

export default SettingForm;
