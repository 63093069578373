import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Modal } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { ProductStatusEnum } from 'enum';
import { Product, ProductDetail } from 'models';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { updateProductContent } from 'services/product-detail.service';

export class FormDetail {
  title: string;
  description: string;
}

interface IProps {
  detail: ProductDetail;
  product: Product;
}

const ProductDetailTab: React.FC<IProps> = ({detail, product}: IProps) => {
  const dataSource = [
    detail,
  ]

  const [formDetail, setFormDetail] = useState<FormDetail>({title: '', description: ''});

  useEffect(() => {
    console.log(detail);
    setFormDetail({
      title: detail.title,
      description: detail.description,
    } as FormDetail);
  }, [detail]);

  const updateContent = async () => {
    console.log(formDetail);
    const body = {
      ...formDetail,
      platformProductId: detail.platformProductId,
    };

    try {
      await updateProductContent(body);
      // setOpenUpdateStatus(true);
      toast.success("Cập nhật thành công");
    } catch(e) {

    }
  }

  const changeTitle = (event: any) => {
    setFormDetail({
      ...formDetail,
      title: event.target.value,
    });
  }

  return (
    <>
      {detail.status != ProductStatusEnum.published && (
        <div>
          <div className="input-group min-w-[265px]">
            <button
              className="btn btn-default-solid btn-sm dark:bg-[#fff] dark:text-[#11047a]"
              onClick={updateContent}
            >
              Cập nhật
            </button>
          </div>
          <div className="margin-30"></div>
          <div className="margin-30"></div>
          <div
              className="input-group min-w-[265px]"
            >
              <label className="dark:!bg-[#111c44] dark:text-[#fff]">
                Description
              </label>
              <CKEditor
                editor={ ClassicEditor }
                    data={formDetail.description}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        // console.log( event, editor.getData() );
                      setFormDetail({
                        ...formDetail,
                        description: editor.getData(),
                      });
                    }}
                    // onBlur={ ( event, editor ) => {
                    //     console.log( 'Blur.', editor.getData() );
                    // }}
                    // onFocus={ ( event, editor ) => {
                    //     console.log( 'Focus.', editor );
                    // } }
              />
          </div>
          
        </div>
      )}
    </>
  );
};
export default ProductDetailTab;
