import {
  MdOutlineWidgets,
  MdOutlinePublic,
  MdHome,
  MdGroup,
  MdExtension,
  MdOutlineApps,
  MdChat,
  MdOutlineSettings,
} from 'react-icons/md';

// Admin Imports
import DashboardsDefault from 'views/admin/dashboards/default';
import CreateTournament from 'features/tournament/page/CreateTournamentPage';
import ProductSearchPage from 'features/product/pages/ProductSearchPage';
import WebsitePage from 'features/website/pages/WebsitePage';
import AffiliatePage from 'features/affiliate/pages/AffiliatePage';
import SignIn from 'views/auth/signIn/SignIn';
import SignUp from 'views/auth/signUp/SignUp';
import SettingPage from 'features/setting/pages/SettingPage';

const routes = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    layout: '/admin',
    icon: <MdHome className="text-inherit h-5 w-5" />,
    component: <DashboardsDefault />,
    menu: true,
  },
  {
    name: 'Websites',
    layout: '/admin',
    path: '/websites',
    icon: <MdOutlinePublic className="text-inherit h-5 w-5" />,
    component: <WebsitePage />,
    menu: true,
  },
  {
    name: 'Products',
    layout: '/admin',
    path: '/products',
    icon: <MdOutlineApps className="text-inherit h-5 w-5" />,
    component: <ProductSearchPage />,
    menu: true,
  },
  {
    name: 'Affiliates',
    layout: '/admin',
    path: '/affiliates',
    icon: <MdExtension className="text-inherit h-5 w-5" />,
    component: <AffiliatePage />,
    menu: true,
  },
  {
    name: 'Settings',
    layout: '/admin',
    path: '/settings',
    icon: <MdOutlineSettings className="text-inherit h-5 w-5" />,
    component: <SettingPage />,
    menu: true,
  },
  
  // {
  //   name: 'Players Detail',
  //   layout: '/admin',
  //   path: '/players/:id',
  //   icon: <MdGroup className="text-inherit h-5 w-5" />,
  //   component: <PlayerDetailPage />,
  // },
  // {
  //   name: 'Game Sessions',
  //   layout: '/admin',
  //   path: '/game-sessions',
  //   icon: <MdRecentActors className="text-inherit h-5 w-5" />,
  //   component: <GameSessionPage />,
  //   menu: true,
  // },
  // {
  //   name: 'Tokens',
  //   layout: '/admin',
  //   path: '/tokens',
  //   icon: <MdGeneratingTokens className="text-inherit h-5 w-5" />,
  //   component: <OthersError />,
  //   menu: true,
  // },
  // {
  //   name: 'Reporting',
  //   layout: '/admin',
  //   path: '/reporting',
  //   icon: <MdOutlineBarChart className="text-inherit h-5 w-5" />,
  //   component: <ReportingPage />,
  //   menu: true,
  // },
  // {
  //   name: 'Chat',
  //   path: '/chat',
  //   icon: <MdChat className="text-inherit h-5 w-5" />,
  //   collapse: true,
  //   menu: true,
  //   items: [
  //     {
  //       name: 'Prohibited words',
  //       layout: '/admin',
  //       path: '/chat/management',
  //       component: <OthersError />,
  //       secondary: true,
  //     },
  //     {
  //       name: 'Prohibited reporting',
  //       layout: '/admin',
  //       path: '/chat/prohibited',
  //       component: <OthersError />,
  //       secondary: true,
  //     },
  //   ],
  // },
  // {
  //   name: 'Banner',
  //   layout: '/admin',
  //   path: '/banner',
  //   icon: <MdPermMedia className="text-inherit h-5 w-5" />,
  //   component: <OthersError />,
  //   menu: true,
  // },
  // {
  //   name: 'Schedule',
  //   layout: '/admin',
  //   path: '/schedule',
  //   icon: <MdSchedule className="text-inherit h-5 w-5" />,
  //   component: <OthersError />,
  //   menu: true,
  // },
  // {
  //   name: 'Settings',
  //   layout: '/admin',
  //   path: '/settings',
  //   icon: <MdSettings className="text-inherit h-5 w-5" />,
  //   component: <AccountSettings />,
  //   menu: true,
  // },

  {
    name: 'Authentication',
    path: '/auth',
    icon: <MdChat className="text-inherit h-5 w-5" />,
    collapse: true,
    // menu: true,
    items: [
      {
        name: 'Sign In',
        layout: '/auth',
        path: '/sign-in',
        component: <SignIn />,
        secondary: true,
      },
      // {
      //   name: 'Sign Up',
      //   layout: '/auth',
      //   path: '/sign-up',
      //   component: <SignUp />,
      //   secondary: true,
      // },
      // {
      //   name: 'Forgot Password',
      //   layout: '/auth',
      //   path: '/forgot-password',
      //   component: <ForgotPassword />,
      //   secondary: true,
      // },
      // {
      //   name: 'Verification',
      //   layout: '/auth',
      //   path: '/verification',
      //   component: <Verification />,
      //   secondary: true,
      // },
      // {
      //   name: 'Lock',
      //   layout: '/auth',
      //   path: '/lock',
      //   component: <Lock />,
      //   secondary: true,
      // },
    ],
  },
] as any[];
export default routes;
