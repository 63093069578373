import React, { useEffect, useState } from 'react';
import Card from 'components/card';
import SearchIcon from 'components/icons/SearchIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from 'features/components/loading';
import _ from 'lodash';
import { Pagination, Select, Table, Modal } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useQuery } from 'commons/useQuery';
import { getListProducts, updateProductStatus } from 'services/product.service';
import { Product } from 'models';
import ProductDetailPage from 'features/product/pages/ProductDetailPage';
import Checkbox from 'components/checkbox';
import { MdAddTask } from "react-icons/md";
import { toast } from 'react-toastify';
import { affiliateService } from 'services/affiliate.service';

const searchField = [
  { label: "Name", field: "name" },
  { label: "Product ID", field: "platformProductId" },
  { label: "Category ID", field: "categoryId" },
  // { label: "Lượt bán min", field: "sold_min" },
  // { label: "Lượt thích min", field: "liked_min" },
  // { label: "Price min", field: "price_min" },
  // { label: "Price max", field: "price_max" },
  // { label: "Discount min", field: "discount_min" },
]

const SearchTableProducts: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = useQuery(search);
  const [products, setProducts] = React.useState([]);
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [total, setTotal] = React.useState<number>(1);

  const [params, setParams] = React.useState<any>({ page: 1, pageSize: 20, status: 'generated' });
  const [checked, setChecked] = React.useState<any>({});
  const [openDetail, setOpenDetail] = React.useState<boolean>(false);
  const [openUpdateStatus, setOpenUpdateStatus] = React.useState<boolean>(false);
  const [detailProduct, setDetailProduct] = React.useState<Product>();
  const [selectedProductIds, setSelectedProductIds] = React.useState<number[]>([]);


  const changeParams = (name: any, value: any) => {
    const param = {
      ...params,
    };
    param[name] = value;
    setParams(param);
  }

  const fetchData = async (params: any) => {
    try {
      const response = await getListProducts(params);
      setProducts(response.data);
      setTotal(response.total);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      console.error(error);
    }
    const newChecked = { "0": false };
    setChecked(newChecked);
  };

  const fetchUpdateStutus = async (ids: number[], status = "generated") => {
    setLoading(true);
    try {
      await updateProductStatus(ids, status);
      setOpenUpdateStatus(true);

      //update checked
      ids.forEach(id => {
        delete checked[id];
      });

      //update products
      if (ids.length == products.length) {
        await fetchData(params);
      } else {
        const newProducts = products.filter(item => {
          return !ids.every(id => id == item.platformProductId);
        });
        setProducts(newProducts);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  const openModalDetail = async (product: Product) => {
    setDetailProduct(product);
    setOpenDetail(true);
  }

  const updateStatus = async () => {
    const ids: number[] = [];
    Object.keys(checked).forEach(key => {
      if (checked[key] && key != "0") {
        ids.push(parseInt(key));
      }
    })

    if (ids.length == 0) return;

    await fetchUpdateStutus(ids);
  }

  const addToAffiliate = () => {
    if (selectedProductIds.length == 0) {
      toast.error("Hãy chọn 1 sản phẩm");
      return;
    }

    affiliateService.add(selectedProductIds)
      .then(() => {
        toast.success(`Thêm ${selectedProductIds.length} sản phẩm thành công vào chương trình Affiliate`);
      })
      .catch(e => {
        toast.error(`Thêm ${selectedProductIds.length} sản phẩm thất bại vào chương trình Affiliate`);
      })
  }

  React.useEffect(() => {
    fetchData(params);
  }, []);

  const searchProduct = () => {
    fetchData(params);
  };

  const handlePageChange = (page: number) => {
    params["page"] = page;
    fetchData(params);
  };

  const onChangeCheckbox = (e: any) => {
    const isCheck = e.target.checked;
    const id = e.target.id;

    const listCheck = { ...checked };
    if (id == "0") {
      (products || []).forEach(product => {
        listCheck[product.platformProductId.toString()] = isCheck;
      });
      listCheck["0"] = isCheck;
    } else {
      listCheck[id] = isCheck;
    }

    setChecked(listCheck);
    const ids: number[] = [];
    Object.keys(listCheck).forEach(key => {
      if (listCheck[key] && key != "0") {
        ids.push(parseInt(key));
      }
    })
    setSelectedProductIds(ids);
  };

  const columns: ColumnsType<Product> = [
    {
      title: <Checkbox id={"0"} checked={checked["0"]} onChange={onChangeCheckbox}></Checkbox>,
      // dataIndex: 'platformProductId',
      // key: 'platformProductId',
      align: 'center',
      render: (value, record) => <Checkbox onChange={onChangeCheckbox}
        id={record?.platformProductId.toString()}
        checked={checked[record?.platformProductId.toString()]}
      >
      </Checkbox>,
    },
    {
      title: 'Id',
      dataIndex: 'platformProductId',
      key: 'platformProductId',
      align: 'center',
      render: (id) => <p className="text-[#333] dark:text-white">{id}</p>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <a
          className="cursor-pointer font-medium text-[#11047a] dark:text-brand-400"
          onClick={(e) => {
            e.preventDefault();
            openModalDetail(record);
          }}
          title="Click to view detail"
        >
          {name}
        </a>
      ),
    },
    {
      title: 'Shop name',
      dataIndex: 'shopName',
      key: 'shopName',
      render: (shopName) => (
        <p className="text-[#333] dark:text-white">{shopName}</p>
      ),
    },
    {
      title: 'Giá gốc',
      dataIndex: 'priceBeforeDiscount',
      key: 'priceBeforeDiscount',
      render: (value) => (
        <p className="text-[#333] dark:text-white">{value.toLocaleString('vn', {
          style: 'currency',
          currency: 'VND',
        })}</p>
      ),
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      key: 'price',
      render: (value) => (
        <p className="text-[#333] dark:text-white">{value.toLocaleString('vn', {
          style: 'currency',
          currency: 'VND',
        })}</p>
      ),
    },
    {
      title: 'Lượt bán',
      dataIndex: 'historySold',
      key: 'historySold',
      render: (value) => (
        <p className="text-[#333] dark:text-white">{value}</p>
      ),
    },
    {
      title: 'Lượt thích',
      dataIndex: 'likedCount',
      key: 'likedCount',
      render: (value) => (
        <p className="text-[#333] dark:text-white">{value}</p>
      ),
    },
    {
      title: 'DETAIL',
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      render: (action, record) => (
        <div className="flex items-center justify-center">
          <a
            className="btn btn-default inline-block !w-[100px]"
            onClick={(e) => {
              e.preventDefault();
              openModalDetail(record);
            }}
            title="Click to view detail"
          >
            Detail
          </a>
        </div>
      ),
    },
  ];

  return (
    <Card extra={'w-full h-full sm:overflow-auto px-6'}>
      <div
        className="search-box mt-[20px] flex w-full items-center gap-[20px] rounded-xl pt-[20px]"
        id="search-box"
      >
        {searchField.map(item => (
          <div className="input-group flex-1" id={"search-" + item.field} key={"search-" + item.field}>
            <label className="dark:!bg-[#111c44] dark:text-[#fff]">
              {item.label}
            </label>
            <div className={`input-form dark:!bg-[#111c44] dark:text-white`}>
              <SearchIcon />
              <input
                value={params[item.field]}
                onChange={(e) => changeParams(item.field, e.target.value)}
                type="text"
                placeholder={item.label}
                className={`input-text dark:!bg-[#111c44] dark:text-white`}
              />
            </div>
          </div>
        ))}

        <div className="">
          <button
            className="btn btn-default-solid btn-lg dark:bg-[#fff] dark:text-[#11047a]"
            onClick={searchProduct}
          >
            Search
          </button>
        </div>
      </div>
      <div className="flex w-full gap-[20px] rounded-xl pt-[20px]">
        <a className="dark:bg-[#fff] dark:text-[#11047a] flex items-center cursor-pointer"
        onClick={addToAffiliate}
        >
          <MdAddTask /> <span className='pl-1'>Thêm vào Affilate Program ({selectedProductIds.length})</span>
        </a>
      </div>
      <div className="content-box mt-2 overflow-x-auto xl:overflow-x-hidden">
        {loading ? (
          <Loading />
        ) : (
          <>
            <Table
              dataSource={products}
              columns={columns}
              pagination={false}
              rowClassName="dark:!bg-navy-800 dark:text-white"
              rowKey="id"
              sortDirections={['ascend', 'descend']}
              showSorterTooltip={false}
            />
            <Pagination
              current={params["page"]}
              pageSize={params["pageSize"]}
              total={total}
              onChange={handlePageChange}
              className="pagination-box my-[20px] flex justify-end"
            />
          </>
        )}

        <Modal
          title="Product Detail"
          open={openDetail}
          centered
          onCancel={() => setOpenDetail(false)}
          footer={null}
          width={window.innerWidth - 200}
        >
          <>
            <ProductDetailPage product={detailProduct}></ProductDetailPage>
          </>
        </Modal>
      </div>
    </Card>
  );
};

export default SearchTableProducts;
