import Card from 'components/card';
import { AffiliateProduct } from 'models/affiliateProduct';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

type Props = {
  product: AffiliateProduct;
};

const ProductAffiliateDetail = ({ product }: Props) => {


  return (
    <Card extra={'w-full h-full bg-white mt-3 px-4 pt-3 pb-8'}>
      <div
        className="input-group min-w-[265px] min-h-[100px]"
      >
        <label className="dark:!bg-[#111c44] dark:text-[#fff]">
          Short Description
        </label>
        <CKEditor
          editor={ClassicEditor}
          data={product.shortDescription || ''}
        />
      </div>
      <div
        className="input-group min-w-[265px] min-h-[400px] mt-5"
      >
        <label className="dark:!bg-[#111c44] dark:text-[#fff]">
          Description
        </label>
        <CKEditor
          editor={ClassicEditor}
          data={product.description || ''}
        />
      </div>
    </Card >
  );
};
export default ProductAffiliateDetail;
