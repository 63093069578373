import logoBlack from 'assets/img/logo/logo-black.png';
import logoWhite from 'assets/img/logo/logo-white.png';

const Footer = () => {
  return (
    <div className="flex w-full flex-col items-center justify-center px-1 pb-8 pt-3 lg:px-8 xl:flex-row">
      <p className="mb-4 text-center text-sm font-medium text-[#333] dark:text-gray-600 sm:!mb-0 md:text-lg">
        <span className="mb-4 flex gap-[4px] text-center text-sm text-[#333] dark:text-gray-600 sm:!mb-0 md:text-base items-center">
          ©{new Date().getFullYear()}{' '}
          <img src={`${logoBlack}`} className="w-[140px]  dark:hidden" />{' '}
          <img src={`${logoWhite}`} className="hidden  w-[140px] dark:block" />
        </span>
      </p>
    </div>
  );
};

export default Footer;
