import avatar from 'assets/img/avatars/avatar4.png';
import { BrandTexts } from 'commons/constants';
import Dropdown from 'components/dropdown';
import React, { useMemo } from 'react';
import { FiAlignJustify, FiSearch } from 'react-icons/fi';
import { RiMoonFill, RiSunFill } from 'react-icons/ri';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
}) => {
  const url = useLocation();
  const partName = url.pathname.split('/');
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = React.useState(
    document.body.classList.contains('dark')
  );

  const navigate = useNavigate();

  const breadCrumbValue = useMemo(() => {
    if (partName[1] === BrandTexts.PLAYERS && Number.isFinite(Number(partName[2])))
      return partName[2];
    return '';
  }, [url]);

  const logout = () => {
    localStorage.removeItem('token');
    navigate("/auth/sign-in");
  }

  return (
    <nav className="fixed right-3 top-3 z-50 flex w-[calc(100vw_-_6%)] flex-row flex-wrap items-center justify-between rounded-xl  bg-white p-2 dark:bg-[#0b14374d] md:right-[30px] md:top-4 md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:top-0 xl:w-[calc(100vw_-_350px)] xl:pt-[20px] 2xl:w-[calc(100vw_-_365px)]">
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1">
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {' '}
              /{' '}
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="players"
          >
            {brandText}
          </Link>
          {breadCrumbValue ? (
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {' '}
              / {breadCrumbValue}
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="header-top relative z-10 mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
        <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div>
        <span
          className="flex cursor-pointer text-xl text-[#333] dark:text-white xl:hidden "
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        <div
          className="cursor-pointer text-[#333] dark:text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove('dark');
              setDarkmode(false);
            } else {
              document.body.classList.add('dark');
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-[#333] dark:text-gray-600" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-[#333] dark:text-gray-600" />
          )}
        </div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full"
              src={avatar}
              alt="Elon Musk"
            />
          }
          children={
            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="ml-4 mt-3">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, Adela
                  </p>{' '}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="ml-4 mt-3 flex flex-col">
                <a
                  href=" "
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </a>
                <a
                  href=" "
                  className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Newsletter Settings
                </a>
                <span
                  onClick={ logout }
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500 cursor-pointer"
                >
                  Log Out
                </span>
              </div>
            </div>
          }
          classNames={'py-2 top-8 -left-[180px] w-max'}
        />
      </div>
    </nav>
  );
};

export default Navbar;
