import { API_URL } from 'app-constants';
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

export const axiosClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a request interceptor
axiosClient.interceptors.request.use(function (
  config: InternalAxiosRequestConfig
) {
  config = {
    ...config,
    headers: {
      ...config.headers,
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  } as InternalAxiosRequestConfig;

  return config;
});

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/auth/sign-in';

      return;
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
