import { Card } from "antd";
import InputField from 'components/fields/InputField';
import SelectField from "components/fields/SelectField";
import { Website } from "models/website";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { affiliateService } from "services/affiliate.service";
import { websiteService } from "services/website.service";

const PublishForm = (props: {
    productIds: number[]
    onClose?: any;
}) => {
    const [domain, setDomain] = useState<string>(null);
    const [listDomains, setListDomains] = useState<any[]>([]);

    useEffect(() => {
        websiteService.getAll().then((res) => {
            setListDomains(res.map((website) => {
                return {
                    value: website.domain,
                    label: website.domain,
                  }
            }));
        });
    }, []);

    const onChange = (value: any) => {
        setDomain(value);
    }

    const submit = () => {
        if(!domain) {
            toast.error("Hãy chọn domain");
            return;
        }

        affiliateService.publish(props.productIds, domain).then(() => {
            toast.success("Cập nhật thành công");
            props.onClose();
        })
        .catch(() => {
            toast.error("Cập nhật không thành công");
        })
    }

    return (
        <Card>
            <SelectField
                id="domain"
                label="Domain"
                options={listDomains}
                extra="mb-3"
                onChange={(e: any) => onChange(e)}
                value={domain}
                placeholder="Choose domain"
            />

            <div className="mt-[30px] flex justify-center">
                <button
                    className="linear flex flex-row items-center justify-center rounded-xl bg-[#11047a] px-4 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 lg:min-w-[250px]"
                    onClick={submit}
                >
                    Submit
                </button>
            </div>
        </Card>
    );
};

export default PublishForm;
