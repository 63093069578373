import { API_URL } from 'app-constants';
// import axiosClient from './axiosClient';
import axios from 'axios';

const axiosClient = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
})

export const login = (username: string, password: string) => {
    return axiosClient
        .post(API_URL + "/auth/login", {
            username,
            password,
        })
        .then((response) => {
            const token = response.data.access_token;
            localStorage.setItem('token', token);

            return response.data;
        });
};