import Card from "components/card";
import SettingForm from "../components/SettingForm";

const SettingPage = () => {
  return (
    <Card extra={"w-full h-full bg-white mt-3"}>
      <SettingForm />
    </Card>
  );
};

export default SettingPage;
