import Card from 'components/card';
import InputField from 'components/fields/InputField';
import Centered from 'layouts/auth/types/Centered';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { API_URL } from 'app-constants';
import axiosClient from 'services/axiosClient';
import { login } from 'services/auth.service';

const SignIn = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [errorUserName, setErrorUserName] = useState<string>('');
  const [errorPassword, setErrorPassword] = useState<string>('');

  const handleLogin = async () => {
    try {
      if (userName === '') {
        setErrorUserName('UserName is not empty');
      }
      if (password === '') {
        setErrorPassword('Password is not empty');
      }
      
      await login(userName, password);
     
      setTimeout(() => {
        navigate('/dashboard');
      }, 500);
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Centered
      maincard={
        <Card extra="max-w-[405px] md:max-w-[510px] md:w-[510px] h-max mx-2.5 md:mx-auto mt-[50px] mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
          <h3 className="mb-[10px] text-4xl font-bold text-gray-900 dark:text-white">
            Sign In
          </h3>
          <p className="mb-9 ml-1 text-base text-gray-600">
            Enter your email and password to sign in!
          </p>
          {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-700 dark:text-white">
            <div className="rounded-full text-xl">
              <FcGoogle />
            </div>
            <p className="text-sm font-medium text-navy-700 dark:text-white">
              Sign In with Google
            </p>
          </div> */}
          {/* <div className="mb-4 flex items-center gap-3">
            <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
            <p className="text-base font-medium text-gray-600"> or </p>
            <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
          </div> */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="User Name*"
            placeholder="Name"
            id="userName"
            type="text"
            onChange={(e: any) => setUserName(e.target.value.trim())}
            error={errorUserName}
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            onChange={(e: any) => setPassword(e.target.value.trim())}
            onKeyDown={handleKeyDown}
            error={errorPassword}
          />
          {error && <p className="text-[14px] text-red-500">{error}</p>}
          <div className="mt-2 flex items-center justify-between">
            {/* <div className="flex items-center">
              <Checkbox />
              <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                Keep me logged In
              </p>
            </div> */}
            <a
              className="text-sm font-medium text-brand-500 hover:text-brand-500 dark:text-white"
              href="/auth/forgot-password"
            >
              Forgot password?
            </a>
          </div>
          <button
            className="linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            onClick={handleLogin}
          >
            Sign In
          </button>
          {/* <div className="mt-3">
            <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
              Not registered yet?
            </span>
            <a
              href="/auth/sign-up"
              className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-500 dark:text-white"
            >
              Create an Account
            </a>
          </div> */}
        </Card>
      }
    />
  );
};

export default SignIn;
